import { default as _404p9UmcSnXFvMeta } from "/home/node/app/pages/auth/404.vue?macro=true";
import { default as indexJ1us15h2jZMeta } from "/home/node/app/pages/booking/flight/index.vue?macro=true";
import { default as order989BXGKnR8Meta } from "/home/node/app/pages/booking/flight/order.vue?macro=true";
import { default as recapjZKVzEnp4KMeta } from "/home/node/app/pages/booking/flight/recap.vue?macro=true";
import { default as resulttLd4iyuHpFMeta } from "/home/node/app/pages/booking/flight/result.vue?macro=true";
import { default as detailsr38XA4Go92Meta } from "/home/node/app/pages/booking/hotel/details.vue?macro=true";
import { default as indexkVbvjznbGLMeta } from "/home/node/app/pages/booking/hotel/index.vue?macro=true";
import { default as orderLg8XLGzQ3JMeta } from "/home/node/app/pages/booking/hotel/order.vue?macro=true";
import { default as resultlSpWlgNuHVMeta } from "/home/node/app/pages/booking/hotel/result.vue?macro=true";
import { default as detailsfKdj9DT3Z3Meta } from "/home/node/app/pages/booking/rail/details.vue?macro=true";
import { default as indexsWawBZuBwUMeta } from "/home/node/app/pages/booking/rail/index.vue?macro=true";
import { default as orderkdfzbjjG1bMeta } from "/home/node/app/pages/booking/rail/order.vue?macro=true";
import { default as resultaZ9qe2vkKsMeta } from "/home/node/app/pages/booking/rail/result.vue?macro=true";
import { default as d1009e666800vuKS7fryZDNMeta } from "/home/node/app/pages/d1009e666800v.vue?macro=true";
import { default as indexaHvjMleZpWMeta } from "/home/node/app/pages/folder/[id]/edit/index.vue?macro=true";
import { default as recapvcpl53h5pIMeta } from "/home/node/app/pages/folder/[id]/edit/recap.vue?macro=true";
import { default as index_45oldLKGzFWBpjsMeta } from "/home/node/app/pages/folder/[id]/index-old.vue?macro=true";
import { default as indexIuQLJeGExYMeta } from "/home/node/app/pages/folder/[id]/index.vue?macro=true";
import { default as indexDSs12Fc0ARMeta } from "/home/node/app/pages/folder/index.vue?macro=true";
import { default as indexHLjAgSANMfMeta } from "/home/node/app/pages/help/index.vue?macro=true";
import { default as _91category_93uYotyrQOonMeta } from "/home/node/app/pages/help/partners/[category].vue?macro=true";
import { default as index0uyutkQdfCMeta } from "/home/node/app/pages/help/partners/index.vue?macro=true";
import { default as indexca63Ho8mnZMeta } from "/home/node/app/pages/index.vue?macro=true";
import { default as sales_45journalNMmybKvZRVMeta } from "/home/node/app/pages/journal/sales-journal.vue?macro=true";
import { default as uettrxmvnhvjuQFMeta } from "/home/node/app/pages/journal/uettr.vue?macro=true";
import { default as notificationmpjmB4QtqoMeta } from "/home/node/app/pages/notification.vue?macro=true";
import { default as indexAQGvOmutWaMeta } from "/home/node/app/pages/sales-journal/index.vue?macro=true";
export default [
  {
    name: _404p9UmcSnXFvMeta?.name ?? "auth-404",
    path: _404p9UmcSnXFvMeta?.path ?? "/auth/404",
    children: [],
    meta: _404p9UmcSnXFvMeta,
    alias: _404p9UmcSnXFvMeta?.alias || [],
    redirect: _404p9UmcSnXFvMeta?.redirect || undefined,
    component: () => import("/home/node/app/pages/auth/404.vue").then(m => m.default || m)
  },
  {
    name: indexJ1us15h2jZMeta?.name ?? "booking-flight",
    path: indexJ1us15h2jZMeta?.path ?? "/booking/flight",
    children: [],
    meta: indexJ1us15h2jZMeta,
    alias: indexJ1us15h2jZMeta?.alias || [],
    redirect: indexJ1us15h2jZMeta?.redirect || undefined,
    component: () => import("/home/node/app/pages/booking/flight/index.vue").then(m => m.default || m)
  },
  {
    name: order989BXGKnR8Meta?.name ?? "booking-flight-order",
    path: order989BXGKnR8Meta?.path ?? "/booking/flight/order",
    children: [],
    meta: order989BXGKnR8Meta,
    alias: order989BXGKnR8Meta?.alias || [],
    redirect: order989BXGKnR8Meta?.redirect || undefined,
    component: () => import("/home/node/app/pages/booking/flight/order.vue").then(m => m.default || m)
  },
  {
    name: recapjZKVzEnp4KMeta?.name ?? "booking-flight-recap",
    path: recapjZKVzEnp4KMeta?.path ?? "/booking/flight/recap",
    children: [],
    meta: recapjZKVzEnp4KMeta,
    alias: recapjZKVzEnp4KMeta?.alias || [],
    redirect: recapjZKVzEnp4KMeta?.redirect || undefined,
    component: () => import("/home/node/app/pages/booking/flight/recap.vue").then(m => m.default || m)
  },
  {
    name: resulttLd4iyuHpFMeta?.name ?? "booking-flight-result",
    path: resulttLd4iyuHpFMeta?.path ?? "/booking/flight/result",
    children: [],
    meta: resulttLd4iyuHpFMeta,
    alias: resulttLd4iyuHpFMeta?.alias || [],
    redirect: resulttLd4iyuHpFMeta?.redirect || undefined,
    component: () => import("/home/node/app/pages/booking/flight/result.vue").then(m => m.default || m)
  },
  {
    name: detailsr38XA4Go92Meta?.name ?? "booking-hotel-details",
    path: detailsr38XA4Go92Meta?.path ?? "/booking/hotel/details",
    children: [],
    meta: detailsr38XA4Go92Meta,
    alias: detailsr38XA4Go92Meta?.alias || [],
    redirect: detailsr38XA4Go92Meta?.redirect || undefined,
    component: () => import("/home/node/app/pages/booking/hotel/details.vue").then(m => m.default || m)
  },
  {
    name: indexkVbvjznbGLMeta?.name ?? "booking-hotel",
    path: indexkVbvjznbGLMeta?.path ?? "/booking/hotel",
    children: [],
    meta: indexkVbvjznbGLMeta,
    alias: indexkVbvjznbGLMeta?.alias || [],
    redirect: indexkVbvjznbGLMeta?.redirect || undefined,
    component: () => import("/home/node/app/pages/booking/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: orderLg8XLGzQ3JMeta?.name ?? "booking-hotel-order",
    path: orderLg8XLGzQ3JMeta?.path ?? "/booking/hotel/order",
    children: [],
    meta: orderLg8XLGzQ3JMeta,
    alias: orderLg8XLGzQ3JMeta?.alias || [],
    redirect: orderLg8XLGzQ3JMeta?.redirect || undefined,
    component: () => import("/home/node/app/pages/booking/hotel/order.vue").then(m => m.default || m)
  },
  {
    name: resultlSpWlgNuHVMeta?.name ?? "booking-hotel-result",
    path: resultlSpWlgNuHVMeta?.path ?? "/booking/hotel/result",
    children: [],
    meta: resultlSpWlgNuHVMeta,
    alias: resultlSpWlgNuHVMeta?.alias || [],
    redirect: resultlSpWlgNuHVMeta?.redirect || undefined,
    component: () => import("/home/node/app/pages/booking/hotel/result.vue").then(m => m.default || m)
  },
  {
    name: detailsfKdj9DT3Z3Meta?.name ?? "booking-rail-details",
    path: detailsfKdj9DT3Z3Meta?.path ?? "/booking/rail/details",
    children: [],
    meta: detailsfKdj9DT3Z3Meta,
    alias: detailsfKdj9DT3Z3Meta?.alias || [],
    redirect: detailsfKdj9DT3Z3Meta?.redirect || undefined,
    component: () => import("/home/node/app/pages/booking/rail/details.vue").then(m => m.default || m)
  },
  {
    name: indexsWawBZuBwUMeta?.name ?? "booking-rail",
    path: indexsWawBZuBwUMeta?.path ?? "/booking/rail",
    children: [],
    meta: indexsWawBZuBwUMeta,
    alias: indexsWawBZuBwUMeta?.alias || [],
    redirect: indexsWawBZuBwUMeta?.redirect || undefined,
    component: () => import("/home/node/app/pages/booking/rail/index.vue").then(m => m.default || m)
  },
  {
    name: orderkdfzbjjG1bMeta?.name ?? "booking-rail-order",
    path: orderkdfzbjjG1bMeta?.path ?? "/booking/rail/order",
    children: [],
    meta: orderkdfzbjjG1bMeta,
    alias: orderkdfzbjjG1bMeta?.alias || [],
    redirect: orderkdfzbjjG1bMeta?.redirect || undefined,
    component: () => import("/home/node/app/pages/booking/rail/order.vue").then(m => m.default || m)
  },
  {
    name: resultaZ9qe2vkKsMeta?.name ?? "booking-rail-result",
    path: resultaZ9qe2vkKsMeta?.path ?? "/booking/rail/result",
    children: [],
    meta: resultaZ9qe2vkKsMeta,
    alias: resultaZ9qe2vkKsMeta?.alias || [],
    redirect: resultaZ9qe2vkKsMeta?.redirect || undefined,
    component: () => import("/home/node/app/pages/booking/rail/result.vue").then(m => m.default || m)
  },
  {
    name: d1009e666800vuKS7fryZDNMeta?.name ?? "d1009e666800v",
    path: d1009e666800vuKS7fryZDNMeta?.path ?? "/d1009e666800v",
    children: [],
    meta: d1009e666800vuKS7fryZDNMeta,
    alias: d1009e666800vuKS7fryZDNMeta?.alias || [],
    redirect: d1009e666800vuKS7fryZDNMeta?.redirect || undefined,
    component: () => import("/home/node/app/pages/d1009e666800v.vue").then(m => m.default || m)
  },
  {
    name: indexaHvjMleZpWMeta?.name ?? "folder-id-edit",
    path: indexaHvjMleZpWMeta?.path ?? "/folder/:id/edit",
    children: [],
    meta: indexaHvjMleZpWMeta,
    alias: indexaHvjMleZpWMeta?.alias || [],
    redirect: indexaHvjMleZpWMeta?.redirect || undefined,
    component: () => import("/home/node/app/pages/folder/[id]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: recapvcpl53h5pIMeta?.name ?? "folder-id-edit-recap",
    path: recapvcpl53h5pIMeta?.path ?? "/folder/:id/edit/recap",
    children: [],
    meta: recapvcpl53h5pIMeta,
    alias: recapvcpl53h5pIMeta?.alias || [],
    redirect: recapvcpl53h5pIMeta?.redirect || undefined,
    component: () => import("/home/node/app/pages/folder/[id]/edit/recap.vue").then(m => m.default || m)
  },
  {
    name: index_45oldLKGzFWBpjsMeta?.name ?? "folder-id-index-old",
    path: index_45oldLKGzFWBpjsMeta?.path ?? "/folder/:id/index-old",
    children: [],
    meta: index_45oldLKGzFWBpjsMeta,
    alias: index_45oldLKGzFWBpjsMeta?.alias || [],
    redirect: index_45oldLKGzFWBpjsMeta?.redirect || undefined,
    component: () => import("/home/node/app/pages/folder/[id]/index-old.vue").then(m => m.default || m)
  },
  {
    name: indexIuQLJeGExYMeta?.name ?? "folder-id",
    path: indexIuQLJeGExYMeta?.path ?? "/folder/:id",
    children: [],
    meta: indexIuQLJeGExYMeta,
    alias: indexIuQLJeGExYMeta?.alias || [],
    redirect: indexIuQLJeGExYMeta?.redirect || undefined,
    component: () => import("/home/node/app/pages/folder/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexDSs12Fc0ARMeta?.name ?? "folder",
    path: indexDSs12Fc0ARMeta?.path ?? "/folder",
    children: [],
    meta: indexDSs12Fc0ARMeta,
    alias: indexDSs12Fc0ARMeta?.alias || [],
    redirect: indexDSs12Fc0ARMeta?.redirect || undefined,
    component: () => import("/home/node/app/pages/folder/index.vue").then(m => m.default || m)
  },
  {
    name: indexHLjAgSANMfMeta?.name ?? "help",
    path: indexHLjAgSANMfMeta?.path ?? "/help",
    children: [],
    meta: indexHLjAgSANMfMeta,
    alias: indexHLjAgSANMfMeta?.alias || [],
    redirect: indexHLjAgSANMfMeta?.redirect || undefined,
    component: () => import("/home/node/app/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: _91category_93uYotyrQOonMeta?.name ?? "help-partners-category",
    path: _91category_93uYotyrQOonMeta?.path ?? "/help/partners/:category",
    children: [],
    meta: _91category_93uYotyrQOonMeta,
    alias: _91category_93uYotyrQOonMeta?.alias || [],
    redirect: _91category_93uYotyrQOonMeta?.redirect || undefined,
    component: () => import("/home/node/app/pages/help/partners/[category].vue").then(m => m.default || m)
  },
  {
    name: index0uyutkQdfCMeta?.name ?? "help-partners",
    path: index0uyutkQdfCMeta?.path ?? "/help/partners",
    children: [],
    meta: index0uyutkQdfCMeta,
    alias: index0uyutkQdfCMeta?.alias || [],
    redirect: index0uyutkQdfCMeta?.redirect || undefined,
    component: () => import("/home/node/app/pages/help/partners/index.vue").then(m => m.default || m)
  },
  {
    name: indexca63Ho8mnZMeta?.name ?? "index",
    path: indexca63Ho8mnZMeta?.path ?? "/",
    children: [],
    meta: indexca63Ho8mnZMeta,
    alias: indexca63Ho8mnZMeta?.alias || [],
    redirect: indexca63Ho8mnZMeta?.redirect || undefined,
    component: () => import("/home/node/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: sales_45journalNMmybKvZRVMeta?.name ?? "journal-sales-journal",
    path: sales_45journalNMmybKvZRVMeta?.path ?? "/journal/sales-journal",
    children: [],
    meta: sales_45journalNMmybKvZRVMeta,
    alias: sales_45journalNMmybKvZRVMeta?.alias || [],
    redirect: sales_45journalNMmybKvZRVMeta?.redirect || undefined,
    component: () => import("/home/node/app/pages/journal/sales-journal.vue").then(m => m.default || m)
  },
  {
    name: uettrxmvnhvjuQFMeta?.name ?? "journal-uettr",
    path: uettrxmvnhvjuQFMeta?.path ?? "/journal/uettr",
    children: [],
    meta: uettrxmvnhvjuQFMeta,
    alias: uettrxmvnhvjuQFMeta?.alias || [],
    redirect: uettrxmvnhvjuQFMeta?.redirect || undefined,
    component: () => import("/home/node/app/pages/journal/uettr.vue").then(m => m.default || m)
  },
  {
    name: notificationmpjmB4QtqoMeta?.name ?? "notification",
    path: notificationmpjmB4QtqoMeta?.path ?? "/notification",
    children: [],
    meta: notificationmpjmB4QtqoMeta,
    alias: notificationmpjmB4QtqoMeta?.alias || [],
    redirect: notificationmpjmB4QtqoMeta?.redirect || undefined,
    component: () => import("/home/node/app/pages/notification.vue").then(m => m.default || m)
  },
  {
    name: indexAQGvOmutWaMeta?.name ?? "sales-journal",
    path: indexAQGvOmutWaMeta?.path ?? "/sales-journal",
    children: [],
    meta: indexAQGvOmutWaMeta,
    alias: indexAQGvOmutWaMeta?.alias || [],
    redirect: indexAQGvOmutWaMeta?.redirect || undefined,
    component: () => import("/home/node/app/pages/sales-journal/index.vue").then(m => m.default || m)
  }
]